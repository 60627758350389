<template>
  <div class="normal-left-wrap card-padding">
    <template v-if="+type===1">
      <div class="top">
        <div class="title-status-wrap">
          <div class="card-title">{{ titleType }}信息</div>
          <!-- <div :class="['border',+orderInformation.goodsStop===1?'border-red':'border-green' ]">{{ +orderInformation.goodsStop===1?'已':'未' }}报停</div> -->
          <!--0已取消  1 2 4 5 6 7  未报停 8 已报停 9运输完成-->
          <!-- <div :class="['border',[8].includes(+orderInformation.state)?'border-red':'border-green']">{{ $CONSTANT.goodsStateMap.O[+orderInformation.state] }}</div> -->
          <div :class="['border',goodsStateColorMap[+orderInformation.state]]">{{ goodsStateMap[+orderInformation.state] }}</div>
        </div>
        <div class="type-label-wrap">
          <div class="item1">{{ goodsBillSourceMap[+orderInformation.source]||'--' }}</div>
          <div class="item2">{{ goodsBillTypeMap[+orderInformation.type]||'--' }}</div>
          <!--货源详情 未承接 定价报车 不显示定时开标  -->
          <!-- goodsBillType=2竞价报车 isTimerBid是否定时开标 1是 0否 -->
          <!-- <div v-if="+$route.query.goodsBillType===2&&+orderInformation.isTimerBid===1" class="item3">定时开标</div> -->
          <div v-if="+orderInformation.type===2&&orderInformation.extMap&&+orderInformation.extMap.bidEnable===1" class="item3">定时开标</div>
        </div>
      <!-- <div v-if="+$route.query.status===4" class="lose-bid">未中标</div> -->
      </div>
      <div class="order-detail">
        <div class="detail-list">
          <i class="iconfont icon-building-3-line" />
          <UserInfoCard style="width:calc(100% - 27px)" :user-info="orderInformation" :user-type="orderInformation.source" />
        </div>
        <div class="detail-list">
          <i class="iconfont icon-huowu" />
          <div>
            <div class="flex-c">
              <span class="main-text">{{ orderInformation.goodsName||orderInformation.productName||'--' }}</span>
            </div>
            <div class="flex-c">
              <span>产品单价：</span>
              <span>{{ orderInformation.extMap?$parseNumber(orderInformation.extMap.prodPrice)||'--':'--' }}元/吨</span>
            </div>
            <div class="label-wrap">
              <template v-if="orderInformation.tags">
                <span v-for="(item, index) in orderInformation.tags.split(',')" :key="index">{{ item||'--' }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-shuliang" />
          <div>
            <div class="main-text">
              <span>运输数量 </span>
              <span>{{ $parseNumber(orderInformation.total,1000)|| '--' }}吨 {{ orderInformation.truckNum||'--' }}车</span>
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-yunfeidanjia" />
          <div>
            <!-- 定价显示运价 -->
            <div v-if="+orderInformation.type===1" class="main-text">
              <span>运价：</span>
              <span>{{ $parseNumber(orderInformation.price)|| '--' }}{{ unitMap[+orderInformation.priceUnit]||'--' }}</span>
            </div>
            <div>
              <span>合理损耗：</span>
              <span v-if="orderInformation.extMap">{{ permitTypeMap[+orderInformation.extMap.permitType]||'-' }} </span>
              <span v-if="orderInformation.extMap">{{ $parseNumber(orderInformation.extMap.permitLose)||'--' }}‰</span>
            </div>
            <div>
              <span>是否含税：</span>
              <span v-if="orderInformation.extMap">{{ $CONSTANT.taxMap[+orderInformation.extMap.needTax]||'--' }}</span>
            </div>
            <!-- 运单详情2 未申请卸结 显示两个方式 否正一个结算方式 -->
            <div>
              <span>结算方式：</span>
              <span v-if="orderInformation.extMap">{{ settlementTypeMap[orderInformation.extMap.settlementType] || '-' }}{{ +orderInformation.extMap.settlementCycle||'-' }}天 {{ orderInformation.extMap.settlementNeedReceipt==='true' ? '磅单原件' : '' }} </span>
              <!-- 见票15天 磅单原件{{ orderInformation.extMap.settlement||'--' }} -->
            </div>
            <!-- 未申请卸结显示 -->
            <!-- <div v-if="+orderInformation.isWhite===0"> -->
            <span>计费方式：</span>
            <span v-if="orderInformation.extMap">{{ orderInformation.extMap.billing||'--' }}</span>
            <!-- </div> -->
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-xianlu" />
          <div>
            <div class="main-text">
              <span>{{ orderInformation.loadAddrName || '--' }}-{{ orderInformation.unloadAddrName || '--' }}</span>
            </div>
            <template v-if="isShowCourse">
              <div v-for="(item, index) in orderInformation.goodsAddressVOList" :key="index" class="course-list flex" :class="`course-list${index+1}`">
                <img v-if="+item.type === 1" class="course-img" src="@/assets/images/goodsOrder/zhuang.png">
                <img v-else-if="+item.type === 2" class="course-img" src="@/assets/images/goodsOrder/xie.png">
                <div class="course-right">
                  <div class="title">
                    <span>{{ item.addrRegion }}{{ item.addrDetail }}</span>
                  </div>
                  <div class="content">
                    <span>联系人：{{ item.contacter || '--' }}/{{ item.contacterPhone || '--' }}</span>
                  </div>
                </div>
              </div>
            </template>
            <div class="main-c flex-end cursor" @click="isShowCourse=!isShowCourse">查看详情
              <i v-if="!isShowCourse" class="el-icon-arrow-down" />
              <i v-else class="el-icon-arrow-up" />
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-time-line" />
          <div>
            <div class="main-text">
              <span>装{{ orderInformation.loadEarlyTime| parseTime }} 卸{{ orderInformation.unloadEarlyTime| parseTime }}</span>
            </div>
          </div>
        </div>
        <template>
          <!--货源详情 未承接 定价报车;货单跟踪 不显示竞标截止：-->
          <!-- goodsBillType=1竞价报车 isTimerBid是否定时开标 1是 0否 -->
          <div v-if="+orderInformation.type===1">是否允许还价：{{ orderInformation.extMap?priceChangeMap[+orderInformation.extMap.priceChange]||'-':'-' }}</div>
          <div v-if="+orderInformation.type===2&&orderInformation.extMap" class="creat-time">竞标截止：{{ orderInformation.extMap.bidExpireTime | parseTime }}</div>
          <div class="creat-time">车辆限制：{{ orderInformation.extMap?enableCycleMap[+orderInformation.extMap.enableCycle]||'-':'-' }}</div>
        </template>
        <div>备注：{{ orderInformation.remark || '--' }}</div>
        <div class="send-car">
          <div class="word-break-all">货单编号：{{ orderInformation.goodsSerial || "--" }}</div>
          <!-- <div class="word-break-all">?计划编号：{{ orderInformation.goodsSource?orderInformation.goodsSource.no|| "--":'--' }}</div> -->
          <div>发布时间：{{ orderInformation.createTime | parseTime }}</div>
        </div>
      </div>
    </template>
    <template v-else-if="+type===2">
      <div class="top">
        <div class="title-status-wrap">
          <div class="card-title">{{ titleType }}信息</div>
          <div :class="['border',wayBillStatusColorMap[+orderInformation.state]]">{{ wayBillStatusMap[+orderInformation.state] }}</div>
        </div>
        <div class="type-label-wrap">
          <div class="item1">{{ goodsBillSourceMap[+orderInformation.source]||'--' }}</div>
          <div class="item2">{{ goodsBillTypeMap[+orderInformation.type]||'--' }}</div>
          <!--货源详情 未承接 定价报车 不显示定时开标  -->
          <!-- goodsBillType=2竞价报车 isTimerBid是否定时开标 1是 0否 -->
          <!-- <div v-if="+$route.query.goodsBillType===2&&+orderInformation.isTimerBid===1" class="item3">定时开标</div> -->
          <div v-if="+orderInformation.type===2&&orderInformation.extMap&&+orderInformation.extMap.bidEnable===1" class="item3">定时开标</div>
        </div>
      </div>
      <div class="order-detail">
        <div class="detail-list">
          <i class="iconfont icon-building-3-line" />
          <UserInfoCard style="width:calc(100% - 27px)" :user-info="orderInformation" :user-type="orderInformation.source" />
        </div>
        <div class="detail-list">
          <i class="iconfont icon-huowu" />
          <div>
            <div class="flex-c">
              <span class="main-text">{{ orderInformation.goodsName||orderInformation.productName||'--' }}</span>
            </div>
            <div class="flex-c">
              <span>产品单价：</span>
              <span>{{ orderInformation.extMap?$parseNumber(orderInformation.extMap.prodPrice)||'--':'--' }}元/吨</span>
            </div>
            <div class="label-wrap">
              <template v-if="orderInformation.tags">
                <span v-for="(item, index) in orderInformation.tags.split(',')" :key="index">{{ item||'--' }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-shuliang" />
          <div>
            <div class="main-text">
              <span>运输数量 </span>
              <span>{{ $parseNumber(orderInformation.total,1000)|| '--' }}吨 {{ orderInformation.truckNum||'--' }}车</span>
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-yunfeidanjia" />
          <div>
            <div class="main-text">
              <span>承接价格：</span>
              <span>{{ $parseNumber(orderInformation.price)|| '--' }}{{ unitMap[+orderInformation.priceUnit]||'--' }}</span>
              <!-- <span class="yellow-c land-wrap">{{ +orderInformation.isWhite===1?'卸结':'不卸结' }}</span> -->
            </div>
            <!-- <div>
              <span>信息费：</span>
              <span>{{ orderInformation.extMap?+orderInformation.extMap.toPriceType===2?$parseNumber(orderInformation.price)||'--':'--':'--' }}元/车</span>
            </div> -->
            <div>
              <span>合理损耗：</span>
              <span v-if="orderInformation.extMap">{{ permitTypeMap[+orderInformation.extMap.permitType]||'-' }} </span>
              <span v-if="orderInformation.extMap">{{ $parseNumber(orderInformation.extMap.permitLose)||'--' }}‰</span>
            </div>
            <div>
              <span>是否含税：</span>
              <span v-if="orderInformation.extMap">{{ $CONSTANT.taxMap[+orderInformation.extMap.needTax]||'--' }}</span>
            </div>
            <!-- 运单详情2 未申请卸结 显示两个方式 否正一个结算方式 -->
            <div>
              <span>结算方式：</span>
              <span v-if="orderInformation.extMap">{{ settlementTypeMap[orderInformation.extMap.settlementType] || '-' }}{{ +orderInformation.extMap.settlementCycle||'-' }}天 {{ orderInformation.extMap.settlementNeedReceipt==='true' ? '磅单原件' : '' }} </span>
              <!-- 见票15天 磅单原件{{ orderInformation.extMap.settlement||'--' }} -->
            </div>
            <!-- 未申请卸结显示 v-if="+orderInformation.isWhite===0"-->
            <div>
              <span>计费方式：</span>
              <span v-if="orderInformation.extMap">{{ orderInformation.extMap.billing||'--' }}</span>
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-xianlu" />
          <div>
            <div class="main-text">
              <span>{{ orderInformation.loadAddrName || '--' }}-{{ orderInformation.unloadAddrName || '--' }}</span>
            </div>
            <template v-if="isShowCourse">
              <div v-for="(item, index) in orderInformation.goodsAddressVOList" :key="index" class="course-list flex" :class="`course-list${index+1}`">
                <img v-if="+item.type === 1" class="course-img" src="@/assets/images/goodsOrder/zhuang.png">
                <img v-else-if="+item.type === 2" class="course-img" src="@/assets/images/goodsOrder/xie.png">
                <div class="course-right">
                  <div class="title">
                    <span>{{ item.addrRegion }}{{ item.addrDetail }}</span>
                  </div>
                  <div class="content">
                    <span>联系人：{{ item.contacter || '--' }}/{{ item.contacterPhone || '--' }}</span>
                  </div>
                </div>
              </div>
            </template>
            <div class="main-c flex-end cursor" @click="isShowCourse=!isShowCourse">查看详情
              <i v-if="!isShowCourse" class="el-icon-arrow-down" />
              <i v-else class="el-icon-arrow-up" />
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-time-line" />
          <div>
            <div class="main-text">
              <span>装{{ orderInformation.loadEarlyTime| parseTime }} 卸{{ orderInformation.unloadEarlyTime| parseTime }}</span>
            </div>
          </div>
        </div>
        <!--货源详情 未承接 定价报车;货单跟踪 不显示竞标截止：-->
        <!-- <div v-if="!(+$route.query.goodsBillType===2||+$route.query.status===3)" class="creat-time">竞标截止：{{ orderInformation.bidEndTime | parseTime }}</div> -->
        <div v-if="+orderInformation.type===1">是否允许还价：{{ orderInformation.extMap?priceChangeMap[+orderInformation.extMap.priceChange]||'-':'-' }}</div>
        <!-- goodsBillType=1竞价报车 isTimerBid是否定时开标 1是 0否 -->
        <div v-if="+orderInformation.type===2&&orderInformation.extMap&&+orderInformation.extMap.bidEnable===1" class="creat-time">竞标截止：{{ orderInformation.extMap.bidExpireTime | parseTime }}</div>
        <div class="creat-time">车辆限制：{{ orderInformation.extMap?enableCycleMap[+orderInformation.extMap.enableCycle]||'-':'-' }}</div>
        <div>备注：{{ orderInformation.remark || '--' }}</div>
        <div class="send-car">
          <!-- <div class="word-break-all">运单编号：{{ orderInformation.no || "--" }}</div> -->
          <div class="word-break-all">货单编号：{{ orderInformation.goodsSerial||'--' }}</div>
          <div>发布时间：{{ orderInformation.createTime | parseTime }}</div>
        </div>
      </div>
    </template>
    <template v-else-if="+type===3">
      <div class="top">
        <div class="title-status-wrap">
          <div class="card-title">{{ titleType }}信息</div>
          <div :class="['border',vehicleBillStatusColorMap[+orderInformation.state]]">{{ vehicleBillStatusMap[+orderInformation.state] }}</div>
        </div>
      </div>
      <div class="order-detail">
        <!-- 车单 -->
        <div class="detail-list">
          <i class="iconfont icon-yunshuguanli" />
          <div class="flex">
            <span class="car-num">
              <span>{{ orderInformation.truckNo || '--' }}</span>
            </span>
            <span class="car-num">
              <span>{{ orderInformation.guaNo || "--" }}</span>
            </span>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-driver-line" />
          <div class="word-break-all">驾 {{ orderInformation.driverName || "--" }}/{{ orderInformation.driverPhone || "--" }}</div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-supercargo-line" />
          <div class="word-break-all">押 {{ orderInformation.escortName || "--" }}/{{ orderInformation.escortPhone || "--" }}</div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-building-3-line" />
          <UserInfoCard style="width:calc(100% - 27px)" :user-info="orderInformation" :user-type="2" />
          <!-- <div class="word-break-all">联系电话：{{ orderInformation.customerUserPhone || '--' }}</div> -->
        </div>
        <div class="detail-list">
          <i class="iconfont icon-building-3-line" style="visibility: hidden;" />
          <div class="word-break-all">联系电话：{{ orderInformation.customerUserPhone || '--' }}</div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-huowu" />
          <div>
            <div class="flex-c">
              <span class="main-text">{{ orderInformation.goodsName||orderInformation.productName||'--' }}</span>
            </div>
            <div class="flex-c">
              <span>产品单价：</span>
              <span>{{ orderInformation.extMap?$parseNumber(orderInformation.extMap.prodPrice)||'--':'--' }}元/吨</span>
            </div>
            <div class="label-wrap">
              <template v-if="orderInformation.tags">
                <span v-for="(item, index) in orderInformation.tags.split(',')" :key="index">{{ item||'--' }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-yunfeidanjia" />
          <div>
            <div class="main-text">
              <span>上报价格：</span>
              <span>{{ $parseNumber(orderInformation.price)|| '--' }}{{ unitMap[+orderInformation.priceUnit]||'--' }}</span>
            </div>
            <div class="main-text">
              <span>信息费：</span>
              <span>{{ +orderInformation.msgPriceType===2?$parseNumber(orderInformation.msgPrice):'-' }}元/车</span>
            </div>
            <div>
              <span>是否含税：</span>
              <span v-if="orderInformation.extMap">{{ $CONSTANT.taxMap[+orderInformation.extMap.needTax]||'--' }}</span>
            </div>
            <div>
              <span>合理损耗：</span>
              <span v-if="orderInformation.extMap">{{ permitTypeMap[+orderInformation.extMap.permitType]||'-' }} </span>
              <span v-if="orderInformation.extMap">{{ $parseNumber(orderInformation.extMap.permitLose)||'--' }}‰</span>
            </div>
            <!-- 运单详情2 未申请卸结 显示两个方式 否正一个结算方式 -->
            <div>
              <span>结算方式：</span>
              <span v-if="orderInformation.extMap">{{ settlementTypeMap[orderInformation.extMap.settlementType] || '-' }}{{ +orderInformation.extMap.settlementCycle||'-' }}天 {{ orderInformation.extMap.settlementNeedReceipt==='true' ? '磅单原件' : '' }} </span>
            </div>
            <!-- 未申请卸结显示 v-if="+orderInformation.isWhite===0"-->
            <div>
              <span>计费方式：</span>
              <span v-if="orderInformation.extMap">{{ orderInformation.extMap.billing||'--' }}</span>
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-xianlu" />
          <div>
            <div class="main-text">
              <span>{{ orderInformation.loadAddrName || '--' }}-{{ orderInformation.unloadAddrName || '--' }}</span>
            </div>
            <template v-if="isShowCourse">
              <div v-for="(item, index) in orderInformation.goodsAddressVOList" :key="index" class="course-list flex" :class="`course-list${index+1}`">
                <img v-if="+item.type === 1" class="course-img" src="@/assets/images/goodsOrder/zhuang.png">
                <img v-else-if="+item.type === 2" class="course-img" src="@/assets/images/goodsOrder/xie.png">
                <div class="course-right">
                  <div class="title">
                    <span>{{ item.addrRegion }}{{ item.addrDetail }}</span>
                  </div>
                  <div class="content">
                    <span>联系人：{{ item.contacter || '--' }}/{{ item.contacterPhone || '--' }}</span>
                  </div>
                </div>
              </div>
            </template>
            <div class="main-c flex-end cursor" @click="isShowCourse=!isShowCourse">查看详情
              <i v-if="!isShowCourse" class="el-icon-arrow-down" />
              <i v-else class="el-icon-arrow-up" />
            </div>
          </div>
        </div>
        <div class="detail-list">
          <i class="iconfont icon-time-line" />
          <div>
            <div class="main-text">
              <span>装{{ orderInformation.loadEarlyTime| parseTime }} 卸{{ orderInformation.unloadEarlyTime| parseTime }}</span>
            </div>
          </div>
        </div>
        <div>备注：{{ orderInformation.remark || '--' }}</div>
        <div class="send-car">
          <div class="word-break-all">车单编号：{{ orderInformation.carSerial || "--" }}</div>
          <div class="word-break-all">运单编号：{{ orderInformation.transSerial||'--' }}</div>
          <div class="word-break-all">货单编号：{{ orderInformation.goodsSerial|| "--" }}</div>
          <div>派单时间：{{ orderInformation.createTime | parseTime }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { parseTime } from '@/utils';
export default {
  filters: { parseTime },
  props: {
    orderInformation: {
      type: Object,
      default() {
        return {};
      },
    },
    // 三种左侧类型 货单/货源1 运单2 车单3
    type: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    titleType: {
      type: String,
      default() {
        return '货单';
      },
    },
  },
  data() {
    return {
      goodsStateMap: this.$CONSTANT.goodsStateMap.O, // 货单状态map
      goodsStateColorMap: { 0: 'border-grey', 1: 'border-green', 2: 'border-green', 3: 'border-green', 4: 'border-green', 5: 'border-green', 6: 'border-green', 7: 'border-green', 8: 'border-red', 9: 'border-green' }, // 货单状态color map
      wayBillStatusMap: this.$CONSTANT.wayBillStateMap.O, // 运单状态map
      wayBillStatusColorMap: { 0: 'border-grey', 1: 'border-green', 2: 'border-green', 3: 'border-green', 4: 'border-green', 5: 'border-green' }, // 运单状态color map
      vehicleBillStatusMap: this.$CONSTANT.carOrderStateMap.O, // 车单状态map
      vehicleBillStatusColorMap: { 0: 'border-grey', 1: 'border-green', 2: 'border-green', 3: 'border-green', 4: 'border-grey', 5: 'border-green', 6: 'border-grey', 7: 'border-green', 8: 'border-green', 9: 'border-green',
        '-1': 'border-green' }, // 车单状态color map
      goodsBillSourceMap: this.$CONSTANT.goodsSourceMap.O,
      goodsBillTypeMap: {
        1: '定价',
        2: '竞价',
      },
      unitMap: this.$CONSTANT.transportPriceUnitMap.O,
      isShowCourse: false, // 详细地址是否展开
      settlementTypeMap: {
        1: '卸货后',
        2: '对账后',
        3: '开票后',
      },
      permitTypeMap: {
        1: '按单计',
        2: '按车计',
      },
      priceChangeMap: {
        0: '不允许还价',
        1: '允许还价',
      },
      enableCycleMap: {
        0: '不允许循环',
        1: '允许循环',
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.normal-left-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  width: 270px;
  // overflow-y: scroll;
  overflow-y: auto;
  overflow-x: hidden;

  .top{
    position: relative;
    margin-bottom: 20px;
    .title-status-wrap{
      display: flex;
      justify-content: space-between;
      .card-title{
        padding-bottom: 10px;
      }
      .border{
        height: 20px;
        line-height: 18px;
        padding: 0 8px;
      }
    }
    .type-label-wrap{
      display: flex;
      align-items: center;
      font-size: 12px;
      .item1{
        font-size: 14px;
        padding: 2px 10px;
        background: #DFEAFF;
        color: $blue;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .item2,.item3{
        height: 18px;
        padding: 0 6px;
        border-radius: 4px;
        color: #FF5C5C;
        border: 1px solid #FF5C5C;
        margin-left: 5px;
      }
    }
    .lose-bid{
      position: absolute;
      width: 72px;
      height: 72px;
      border: 4px solid #F5F5F5;
      border-radius: 72px;
      right: 10px;
      top: 0;
      line-height: 72px;
      text-align: center;
      font-size: 20px;
      color: #D7D7D7;
      font-weight: bold;
    }
  }
  .order-detail {
    flex: 1;
    overflow-y: scroll;
    margin-right: -10px;
    .detail-list {
      display: flex;
      margin-bottom: 12px;
      i {
        font-size: 20px;
        margin-right: 6px;
        color: #04122b;
      }
      > div {
        width: 100%;
        div {
          padding-bottom: 6px;
          &:last-child {
            padding-bottom: 0;
          }
        }
        .car-num{
          background: #fcb910;
          border-radius: 1px;
          font-size: 12px;
          margin-right: 4px;
          span{
            border: 1px solid #26272a;
            height: 24px;
            margin: 2px;
            display: block;
            padding: 3px 8px;
            color: #26272a;
          }
        }
        .label-wrap{
          >span{
            display: inline-block;
            border: 1px solid $blue;
            padding: 0 6px;
            border-radius: 4px;
            color: $blue;
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
        .land-wrap{
          font-weight: normal;
          margin-left: 5px;
        }
        .course-list {
          .course-img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          .course-right {
            flex: 1;
            position: relative;
            padding-bottom: 10px;
            &::before {
              content: "";
              position: absolute;
              display: block;
              width: 1px;
              border-left: 1px dashed #4385ff;
              top: 26px;
              margin-left: -20.5px;
              height: calc(100% - 27px);
              background: transparent;
            }
            .title {
              > span {
                &:first-child {
                  font-weight: bold;
                }
              }
            }
            .content {
              > span {
                &:first-child {
                  display: inline-block;
                  word-break: break-all;
                }
              }
            }
          }
        }
        .course-list2{
          .course-right {
            padding-bottom: 0;
            &::before {
              display: none;
            }
          }
        }
      }
      .el-icon-arrow-down,.el-icon-arrow-up{
        font-size: 14px;
        color: $blue;
      }
    }
    .send-car {
      > div {
        padding-bottom: 2px;
        &:last-child {
          padding-bottom: 0;
        }
      }
      &::before {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background: #ebeef5;
        margin: 15px 0;
      }
    }
  }
  .word-break-all {
    word-break: break-all;
  }
  // &.card-padding {
  //   padding-right: 0px;
  // }
  //马赛克样式
  .MSK {
    -webkit-filter: blur(5px); /* Chrome, Safari, Opera */
    filter: blur(5px);
  }

}
</style>
